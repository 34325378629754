import React from 'react'

const AboutSectionOne = () => {
    return (
        <section id="about" className="pt-16 md:pt-20 lg:pt-28 bg-primary/[.03]">
            <div className="container">
                <div className="pb-0 md:pb-0 lg:pb-28">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4 lg:w-1/2 lg:mt-0 mt-20">
                            <div
                                className="wow fadeInUp relative mx-auto mb-12 aspect-[25/24] max-w-[500px] text-center lg:m-0"
                                data-wow-delay=".2s"
                            >
                                <img
                                    src="/images/orona/500x480-3.png"
                                    alt="about-image"
                                    fill
                                    className="mx-auto max-w-full lg:mr-0"
                                />
                            </div>
                        </div>

                        <div className="w-full px-4 lg:w-1/2">
                            <div
                                className="wow fadeInUp w-full"
                                data-wow-delay=".1s"
                            >
                                <h2 className="mb-4 text-3xl font-bold !leading-tight text-black dark:text-white sm:text-4xl md:text-[45px]">
                                    Biz Kimiz?
                                </h2>
                                <p className="text-base !leading-relaxed text-body-color md:text-lg">
                                    Orona, başarılı bir marketing hizmeti sunan 2023 yılında kurulan bir şirkettir.
                                </p>
                                <p className="text-base !leading-relaxed text-body-color md:text-lg">
                                    Müşterilerimize, markalarını güçlendirmek ve büyümelerini desteklemek için kapsamlı pazarlama çözümleri sunuyoruz. Orona olarak, işletmelerin dijital dönüşüm yolculuklarında stratejik bir ortak olmayı ve onlara etkileyici sonuçlar elde etmeleri için gerekli araçları sağlamayı hedefliyoruz.
                                </p>
                                <p className="text-base !leading-relaxed text-body-color md:text-lg">
                                    Müşterilerimize özel olarak tasarlanmış ve yenilikçi pazarlama stratejileri sunarak, onların ihtiyaçlarına cevap veriyoruz. Marka bilinirliğini artırmak, hedef kitlelerle bağlantı kurmak ve müşteri sadakatini sağlamak için en son trendleri ve teknolojileri takip ediyoruz. Orona olarak, PR çalışmaları, dijital pazarlama, sosyal medya yönetimi, içerik stratejileri, SEO (Arama Motoru Optimizasyonu) ve reklam kampanyaları gibi çeşitli hizmetler sunarak müşterilerimizin hedeflerine ulaşmalarına yardımcı oluyoruz.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSectionOne