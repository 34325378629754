import React from 'react'

const AboutSectionTwo = () => {
    return (
        <section id="homepage-about" className="pt-16 pb-0 md:pt-20 md:pb-0 lg:py-28">
            <div className="container">
                <div className="-mx-4 flex flex-wrap items-center">
                    <div className="w-full px-4 lg:w-1/2">
                        <div
                            className="wow fadeInUp relative mx-auto mb-12 aspect-[25/24] max-w-[500px] text-center lg:m-0"
                            data-wow-delay=".15s"
                        >
                            <img
                                src="/images/orona/500-480-2.png"
                                alt="about image"
                                fill
                            />
                        </div>
                    </div>
                    <div className="w-full px-4 lg:w-1/2">
                        <div className="wow fadeInUp" data-wow-delay=".2s">
                            <div className="lg:mb-9 mb-0">
                                <h2 className="mb-4 text-3xl font-bold !leading-tight text-black dark:text-white sm:text-4xl md:text-[45px]">
                                    Hakkımızda
                                </h2>
                                <p className="text-base leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                                    Orona Marketing, 2023 yılında kurulan dinamik ve yenilikçi bir pazarlama şirketidir.
                                </p>
                                <p className="text-base leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                                    Müşterilerimize geniş bir yelpazede pazarlama hizmetleri sunarak, işletmelerinin dijital varlıklarını optimize etmelerine ve başarıya ulaşmalarına yardımcı oluyoruz. Müşterilerimizin büyüme hedeflerini gerçeğe dönüştürmek için stratejik düşünce, yaratıcılık ve teknolojiyi bir araya getiriyoruz.
                                </p>
                                <p className="text-base leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                                    Orona Marketing olarak, müşterilerimizin ihtiyaçlarına özel olarak tasarlanmış pazarlama stratejileri sunuyoruz. PR çalışmaları, SEO (Arama Motoru Optimizasyonu), reklam kampanyaları ve daha fazlası gibi geniş bir hizmet yelpazesi sunarak, müşterilerimizin markalarını güçlendirmelerini ve hedef kitleleriyle etkileşimi artırmalarını sağlıyoruz.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSectionTwo