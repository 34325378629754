import React from 'react'
import SectionTitle from './SectionTitle'
import PricingBox from './PricingBox'

const Pricing = () => {
    return (
        <section id="pricing" className="relative z-10 py-16 md:py-20 lg:py-28">
            <div className="container">
                <SectionTitle
                    title="Vizyon ve Misyon"
                    center
                    width="665px"
                />

                <div className="grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-2 lg:grid-cols-2">
                    <PricingBox
                        title="Vizyonumuz"
                        subtitle="Orona Marketing olarak, pazardaki dijital dönüşümü etkin bir şekilde yönetmek ve müşterilerimize sürdürülebilir başarı sağlayan yenilikçi pazarlama çözümleri sunmak için öncü bir şirket olmayı hedefliyoruz. Müşterilerimizin işletmelerini büyütmek ve rekabet avantajı elde etmelerini sağlamak için teknoloji, veri analitiği ve yaratıcı stratejileri bir araya getirerek sektörde öncü olmayı amaçlıyoruz. Vizyonumuz, müşterilerimize en iyi hizmeti sunarken etik değerlere bağlı kalmaktır."
                    />
                    <PricingBox
                        title="Misyonumuz"
                        subtitle="Orona Marketing olarak, müşterilerimize özelleştirilmiş pazarlama stratejileri sunarak onların işletmelerini büyütmelerine yardımcı olmayı misyon ediniyoruz. Müşterilerimizin hedeflerini ve ihtiyaçlarını anlamak için yakından çalışarak, kapsamlı bir strateji geliştiriyoruz ve bu stratejiyi uygulamak için yenilikçi pazarlama tekniklerini kullanıyoruz. Amacımız, markaların dijital varlıklarını optimize etmek, hedef kitleleriyle etkileşimi artırmak ve müşterilerin pazarda rekabet avantajı elde etmelerine yardımcı olmaktır. Misyonumuz doğrultusunda müşterilerimize değer yaratmayı, sürdürülebilir ilişkiler kurmayı ve pazarlama alanında mükemmeliyeti sağlamayı taahhüt ediyoruz."
                    />
                </div>
            </div>

            <div className="absolute left-0 bottom-0 z-[-1]">
                <svg
                    width="239"
                    height="601"
                    viewBox="0 0 239 601"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        opacity="0.3"
                        x="-184.451"
                        y="600.973"
                        width="196"
                        height="541.607"
                        rx="2"
                        transform="rotate(-128.7 -184.451 600.973)"
                        fill="url(#paint0_linear_93:235)"
                    />
                    <rect
                        opacity="0.3"
                        x="-188.201"
                        y="385.272"
                        width="59.7544"
                        height="541.607"
                        rx="2"
                        transform="rotate(-128.7 -188.201 385.272)"
                        fill="url(#paint1_linear_93:235)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_93:235"
                            x1="-90.1184"
                            y1="420.414"
                            x2="-90.1184"
                            y2="1131.65"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#4A6CF7" />
                            <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_93:235"
                            x1="-159.441"
                            y1="204.714"
                            x2="-159.441"
                            y2="915.952"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#4A6CF7" />
                            <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </section>
    )
}

export default Pricing