import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

const Header = () => {
    // Navbar toggle
    const [navbarOpen, setNavbarOpen] = useState(false);
    const navbarToggleHandler = () => {
        setNavbarOpen(!navbarOpen);
    };

    // Sticky Navbar
    const [sticky, setSticky] = useState(false);
    const handleStickyNavbar = () => {
        if (window.scrollY >= 80) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleStickyNavbar);
    });
    return (
        <>
            <header
                className={`header top-0 left-0 z-40 flex w-full items-center bg-transparent ${sticky
                    ? "!fixed !z-[9999] !bg-white !bg-opacity-80 shadow-sticky backdrop-blur-sm !transition dark:!bg-primary dark:!bg-opacity-20"
                    : "absolute"
                    }`}
            >
                <div className="container">
                    <div className="relative -mx-4 flex items-center justify-between">
                        <div className="w-60 max-w-full px-4 xl:mr-12">
                            {
                                sticky ?
                                    <a
                                        href="/"
                                        className={`header-logo block w-full ${sticky ? "py-5 lg:py-2" : "py-8"
                                            } `}
                                    >
                                        <img
                                            src="/images/logo/orona-cmyk.png"
                                            alt="logo"
                                            width="175px"
                                            className="w-full dark:hidden"
                                        />
                                        <img
                                            src="/images/logo/orona-cmyk-disi.png"
                                            alt="logo"
                                            width="175px"
                                            className="hidden dark:block"
                                        />
                                    </a>
                                    :
                                    <a
                                        href="/"
                                        className={`header-logo block w-full ${sticky ? "py-5 lg:py-2" : "py-8"
                                            } `}
                                    >
                                        <img
                                            src="/images/logo/orona-cmyk.png"
                                            alt="logo"
                                            width="175px"
                                            className="w-full dark:hidden"
                                        />
                                        <img
                                            src="/images/logo/orona-cmyk.png"
                                            alt="logo"
                                            width="175px"
                                            className="hidden dark:block"
                                        />
                                    </a>
                            }
                        </div>
                        <div className="flex w-full items-center justify-between px-4">
                            <div>
                                <button
                                    onClick={navbarToggleHandler}
                                    id="navbarToggler"
                                    aria-label="Mobile Menu"
                                    className="absolute right-4 top-1/2 block translate-y-[-50%] rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden"
                                >
                                    <span
                                        className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${navbarOpen ? " top-[7px] rotate-45" : " "
                                            }`}
                                    />
                                    <span
                                        className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${navbarOpen ? "opacity-0 " : " "
                                            }`}
                                    />
                                    <span
                                        className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${navbarOpen ? " top-[-8px] -rotate-45" : " "
                                            }`}
                                    />
                                </button>
                                <nav
                                    id="navbarCollapse"
                                    className={`navbar absolute right-0 z-30 w-[250px] rounded border-[.5px] border-body-color/50 bg-white py-4 px-6 duration-300 dark:border-body-color/20 dark:bg-dark lg:visible lg:static lg:w-auto lg:border-none lg:!bg-transparent lg:p-0 lg:opacity-100 ${navbarOpen
                                        ? "visibility top-full opacity-100"
                                        : "invisible top-[120%] opacity-0"
                                        }`}
                                >
                                    <ul className="block lg:flex lg:space-x-12">
                                        <li className="group relative">
                                            <a
                                                href="#home"
                                                className="flex cursor-pointer items-center justify-between py-2 text-base text-dark group-hover:opacity-70 dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0"
                                            >
                                                Ana Sayfa
                                            </a>
                                        </li>
                                        <li className="group relative">
                                            <a
                                                href='/#features'
                                                className="flex cursor-pointer items-center justify-between py-2 text-base text-dark group-hover:opacity-70 dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0"
                                            >
                                                Neden Orona?
                                            </a>
                                        </li>
                                        <li className="group relative">
                                            <a
                                                href="/#homepage-about"
                                                className="flex cursor-pointer items-center justify-between py-2 text-base text-dark group-hover:opacity-70 dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0"
                                            >
                                                Hakkımızda
                                            </a>
                                        </li>
                                        <li className="group relative">
                                            <a
                                                href="/#about"
                                                className="flex cursor-pointer items-center justify-between py-2 text-base text-dark group-hover:opacity-70 dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0"
                                            >
                                                Biz Kimiz?
                                            </a>
                                        </li>
                                        <li className="group relative">
                                            <a
                                                href="/#pricing"
                                                className="flex cursor-pointer items-center justify-between py-2 text-base text-dark group-hover:opacity-70 dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0"
                                            >
                                                Vizyon ve Misyon
                                            </a>
                                        </li>
                                        <li className="group relative">
                                            <a
                                                href="/#contact"
                                                className="flex cursor-pointer items-center justify-between py-2 text-base text-dark group-hover:opacity-70 dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0"
                                            >
                                                İletişim
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
        </>
    )
}

export default Header;