import React from 'react'

const AboutSectionThree = () => {
    return (
        <section id="homepage-about" className="pt-0 pb-16 md:pb-20 md:pt-0 lg:py-28 lg:pt-10">
            <div className="container">
                <div className="-mx-4 flex flex-wrap items-center">

                    <div className="w-full px-4 lg:w-1/2">
                        <div className="wow fadeInUp" data-wow-delay=".2s">
                            <div className="mb-9">
                                <p className="text-base leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                                    Ekibimizdeki deneyimli pazarlama uzmanları, yaratıcı tasarımcılar ve teknoloji uzmanlarıyla birlikte, müşterilerimize özelleştirilmiş çözümler sunmak için birlikte çalışıyoruz. Her müşterimizin işletmesini ve sektörünü anlamak için yakın bir işbirliği yapıyor ve bu bilgiyi stratejik kararlarımızın temeli olarak kullanıyoruz. Müşterilerimizin hedeflerine ulaşmaları için veri analitiği, trendleri takip etme ve yeni pazarlama yaklaşımlarını benimseme gibi güncel araçları kullanıyoruz.
                                </p>
                                <p className="text-base leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                                    Orona Marketing olarak, müşterilerimize değer yaratmayı ve başarılarını artırmayı taahhüt ediyoruz. Etik değerlere bağlı kalarak, şeffaf iletişim, müşteri odaklılık ve kaliteye odaklanıyoruz. İşbirliği ve uzun vadeli ilişkiler kurarak, müşterilerimizin güvenini kazanıyor ve işletmelerini büyütmek için stratejik bir ortak olarak yanlarında yer alıyoruz.
                                </p>
                                <p className="text-base leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                                    Biz, Orona Marketing olarak, pazardaki dijital dönüşümü etkin bir şekilde yönetmeyi ve müşterilerimize sürdürülebilir başarı sağlayan yenilikçi pazarlama çözümleri sunmayı amaçlıyoruz. Amacımız, müşterilerimizin rekabet avantajı elde etmelerine yardımcı olmak ve onları bir adım öne taşımaktır.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full px-4 lg:w-1/2">
                        <div
                            className="wow fadeInUp relative lg:ml-auto ml-auto lg:mr-0 mr-auto mb-12 aspect-[25/24] max-w-[500px] text-center"
                            data-wow-delay=".15s"
                        >
                            <img
                                src="/images/orona/500x480-1.png"
                                alt="about image"
                                fill
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSectionThree