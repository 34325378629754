import React from 'react'

const AboutSectionFour = () => {
    return (
        <section id="about" className="pt-0 md:pt-0 lg:pt-10 bg-primary/[.03]">
            <div className="container">
                <div className="pb-16 md:pb-20 lg:pb-28">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4 lg:w-1/2">
                            <div
                                className="wow fadeInUp w-full"
                                data-wow-delay=".1s"
                            >
                                <p className="text-base !leading-relaxed text-body-color md:text-lg">
                                    Ekip olarak, deneyimli pazarlama uzmanları, yaratıcı tasarımcılar ve teknoloji uzmanlarından oluşuyoruz. Müşterilerimizin işletmelerini ve sektörlerini anlamak için özveriyle çalışıyor ve stratejik kararlarımızı bu bilgilerle destekliyoruz. İhtiyaçları doğru analiz ederek ve veri analitiğiyle güçlendirilmiş yaklaşımlarla, müşterilerimize özelleştirilmiş ve sonuç odaklı çözümler sunuyoruz.
                                </p>
                                <p className="text-base !leading-relaxed text-body-color md:text-lg">
                                    Orona olarak, müşteri memnuniyetine ve kaliteye önem veriyoruz. Müşterilerimizle yakın işbirliği yaparak, onların hedeflerine uygun stratejiler geliştiriyoruz ve sürekli iletişim halinde kalıyoruz. Değerli müşterilerimizle sağlam ve uzun vadeli ilişkiler kurmak, başarılarınızı paylaşmak ve büyümenize katkıda bulunmak için heyecan duyuyoruz.
                                </p>
                                <p className="text-base !leading-relaxed text-body-color md:text-lg">
                                    Biz, Orona olarak, marketing alanındaki uzmanlığımızı kullanarak müşterilerimizin rekabet avantajı elde etmelerine yardımcı olmayı hedefliyoruz. Amacımız, işletmelerin dijital dünyada öne çıkmasına yardımcı olmak ve onların başarılarını sürdürülebilir kılmaktır.
                                    Sizinle çalışmayı ve işletmenizi büyütme yolculuğunda size eşlik etmeyi dört gözle bekliyoruz. Orona ailesine katılarak, başarıyı birlikte yakalayalım!
                                </p>
                            </div>
                        </div>

                        <div className="w-full px-4 lg:w-1/2 lg:mt-0 mt-20">
                            <div
                                className="wow fadeInUp relative lg:ml-auto ml-auto lg:mr-0 mr-auto mb-12 aspect-[25/24] max-w-[500px] text-center"
                                data-wow-delay=".2s"
                            >
                                <img
                                    src="/images/orona/500x480-4.jpg"
                                    alt="about-image"
                                    fill
                                    className="mx-auto max-w-full lg:mr-0"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSectionFour