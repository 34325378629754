import './App.css';
import Header from "./components/Header"
import ScrollUp from "./components/ScrollUp"
import ScrollToTop from "./components/ScrollToTop"
import Hero from "./components/Hero"
import Features from "./components/Features"
import AboutSectionTwo from './components/AboutSectionTwo';
import AboutSectionThree from './components/AboutSectionThree';
import AboutSectionOne from './components/AboutSectionOne';
import AboutSectionFour from './components/AboutSectionFour';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
    return (
        <>
            <Header />
            <ScrollUp />
            <Hero />
            <Features />
            <AboutSectionTwo />
            <AboutSectionThree />
            <AboutSectionOne />
            <AboutSectionFour />
            <Pricing />
            <Contact />
            <ScrollToTop />
            <Footer />
        </>
    );
}

export default App;
